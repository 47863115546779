
import { Vue, Prop, Watch, Component } from 'vue-property-decorator'
import { Chart } from 'chart.js'
import { getComponent } from '@/utils/helpers'
import annotationPlugin from 'chartjs-plugin-annotation'
import TasqJob from '../../interfaces/tasqs/TasqJob'
import DefermentLabelingModule from '@/store/modules/defermentLabelingModule'
import { Debounce } from 'vue-debounce-decorator'
import zoomPlugin from 'chartjs-plugin-zoom'

@Component({
    components: {
        TasqLoader: () => getComponent('loaders/TasqLoader'),
    }
})
export default class DefermentChart extends Vue {
    // @Prop({ default: () => () => null }) onClick!: (event?: MouseEvent | undefined, activeElements?: {}[] | undefined) => any

    // @Prop({ required: true }) tasq!: TasqJob

    // @Prop({ required: true }) saveThisModel!: boolean

    // @Prop({ required: true }) saveNullModel!: boolean

    // chart: any = false

    // isLoading: boolean = true

    // selected: Date[] = []

    // get isError() {
    //     return DefermentLabelingModule.error
    // }

    // get chartData() {
    //     return JSON.parse(JSON.stringify(DefermentLabelingModule.chartLabelingData))
    // }

    // get chartOptions() {
    //     return JSON.parse(JSON.stringify({
    //         ...DefermentLabelingModule.chartOptions, onClick: this.onClick
    //     }))
    // }

    // created() {
    //     this.$on('saveDefermentMask', this.saveFit)
    //     Chart.pluginService.register(annotationPlugin)
    //     Chart.pluginService.register(zoomPlugin)
    // }

    // destroyed() {
    //     this.$off('saveDefermentMask', this.saveFit)
    // }

    // updated() {
    //     this.saveThisModel && this.saveFit(false)
    //     this.saveNullModel && this.saveFit(true)
    // }

    // saveModel() { console.log("null", this.saveThisModel) }

    // saveFit(Null) {
    //     console.log("Saving Null:", Null)
    //     DefermentLabelingModule.saveFitRun(Null)
    // }

    // resetSelected() {
    //     console.log("resetting")
    //     this.selected = []
    //     this.getFit()
    // }

    // @Debounce(200)
    // async getFit() {
    //     console.log("getfit called")
    //     this.isLoading = true
    //     await DefermentLabelingModule.getFitRun({
    //         nodeid: this.tasq.wellName,
    //         mask: (this.selected || []).sort().map(date => ({ date, Type: 'start' })),
    //         tasq: this.tasq,
    //     }).then((_: any) => { this.updateChart(); this.isLoading = false })
    // }

    // async mounted() {
    //     await this.getFit()
    //     this.selected = DefermentLabelingModule.selectedDates
    // }

    // @Watch("chartData")
    // updateChart() {
    //     const canvas = <HTMLCanvasElement>document.getElementById('defermentChart')
    //     const ctx = canvas.getContext('2d')
    //     var opts = {
    //         ...DefermentLabelingModule.chartOptions, onClick: this.onChartClick, annotation: {
    //             annotations: [
    //                 ...(this.selected || []).map((x: Date) => ({
    //                     type: 'line',
    //                     mode: 'vertical',
    //                     scaleID: 'x-axis-0',
    //                     value: x.toString(),
    //                     borderColor: '#6C78FF',
    //                     borderWidth: 2,
    //                     label: { content: x.toString(), position: "top" }
    //                 })),
    //             ],
    //         }
    //     }
    //     if (ctx) {
    //         if (!this.chart) {
    //             this.chart = new Chart(ctx, {
    //                 type: 'line',
    //                 data: this.chartData,
    //                 options: opts,
    //                 plugins: [annotationPlugin, zoomPlugin]
    //             })
    //         } else {
    //             this.chart.data = this.chartData
    //             this.chart.options = opts
    //         }
    //         this.chart.update()
    //     }
    // }

    // onChartClick(event: any) {
    //     const item = this.chart.getElementAtEvent(event)
    //     if (item[0]) {
    //         // eslint-disable-next-line no-underscore-dangle
    //         const dateIndex = item[0]._index
    //         const date = this.chartData.labels[dateIndex]
    //         console.log("clicked", date)
    //         this.toggleDateOnChart(date)
    //     }
    // }

    // toggleDateOnChart(date) {
    //     // Sometimes dates would get double included, causing an error
    //     let selected = ([... new Set(this.selected)] || []).filter(d => d !== date)
    //     if (selected.indexOf(date) === -1) {
    //         selected.push(date)
    //     }
    //     this.selected = selected
    //     this.getFit()
    // }
}
